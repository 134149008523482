import axios from "axios";
import api from '@/core/api';

export default {
    login(_, payload) {
        return axios
            .post(api.auth, payload)
            .then((response) => {
                const res = response.data;
                let token = res.token;
                localStorage.setItem("admin.csp", token);
                location.reload();
                return true;
            })
            .catch(() => {
                return false;
            });
    },

}
