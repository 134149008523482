export default {
    setItems(state, payload) {
        state.items = payload;
    },
    removeItem(state, payload) {
        state.items = state.items.filter((x) => {
            return x.id != payload;
        });
    },
    publishItem(state, payload) {
        state.items.find(x => x.id === payload).published = true;
    }
}
