import axios from "axios";
import api from '@/core/api';
import Vue from "vue";
import AdvertisementsModel from '../models/advertisements.model';

export default {
    updateItem(_, payload) {
        return axios
            .put(api.advertising + `/${payload.id}`, payload.data)
            .then((response) => {
                const res = response.data;
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getAdByType(_, id) {
        return axios
            .get(api.advertisingByType + `/${id}`)
            .then((response) => {
                const res = new AdvertisementsModel(response.data.ad);
                return res;
            })
            .catch(() => {
                return false;
            });
    },
}
