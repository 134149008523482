import router from '../router';
import axios from "axios";
import api from "../core/api";

export default {
    initAuth() {
        let token = localStorage.getItem("admin.csp");
        if (token)
            return true;
        router.push({name: "login"});
        return false;
    },

    getFileUrl(_, payload) {
        return axios
            .post(api.file, payload)
            .then((res) => {
                return res.data.url;
            })
            .catch(() => {
                return false;
            });
    },
}
