export default {
    props: {
        value: {
            type: Boolean,
        },
    },
    methods: {
        accept() {
            this.$emit("delete");
        },
        close() {
            this.$emit("input", false);
        },
    },
};
