import axios from "axios";
import api from '@/core/api';
import Vue from "vue";
import TeamsModel from "../models/teams.model";
import TeamsUpTypeModel from '../models/teams-up-type.model';

export default {
    getItems({commit}) {
        return axios
            .get(api.team)
            .then((res) => {
                let data = res.data.teams;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new TeamsModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.team, payload)
            .then((res) => {
                Vue.$toast.success(res.data);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    removeItem({commit}, id) {
        return axios
            .delete(api.team + `/${id}`)
            .then((response) => {
                const res = response.data;
                commit("removeItem", id);
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.team + `/${payload.id}`, payload.data)
            .then((response) => {
                const res = response.data;
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.team + `/${id}`)
            .then((response) => {
                const res = new TeamsModel(response.data.teamDto);
                return res;
            })
            .catch(() => {
                return false;
            });
    },

    getUpTypes({commit}) {
        return axios
            .get(api.upType)
            .then((res) => {
                let data = res.data.upTypes;
                commit(
                    "setUpTypes",
                    data.map((i) => {
                        return new TeamsUpTypeModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },
}
