export default {
    computed: {
        currentPage() {
            return this.$route.meta.name;
        },
    },
    methods: {
        logout() {
            this.$store.commit("logout");
        },
    },
};
