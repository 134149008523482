export default {
    auth: 'identity/login',
    setting: 'identity/changePassword',
    game: 'game',
    blog: 'blog',
    blogPublish: 'blog/publish',
    news: 'news',
    newsPublish: 'news/publish',
    category: 'category',
    bookmaker: 'bookmaker',
    advertising: 'advertising',
    team: 'team',
    upType: 'team/getUpTypes',
    advertisingByType: 'advertising/getAdByAdType',
    tournament: 'tournament',
    predictions: 'prediction',
    tournamentType: 'tournament/tournamentTypes',
    matches: 'prediction/getMatches',
    approveResult: 'prediction/approveResult',
    file: 'file/UploadPhoto',

}
