import login from '../views/login/store';
import settings from '../views/settings/store';
import games from '../views/games/store';
import blogs from '../views/blogs/store';
import categories from '../views/categories/store';
import news from '../views/news/store';
import bookmakers from '../views/bookmakers/store';
import teams from '../views/teams/store';
import advertisements from '../views/advertisements/store';
import tournaments from '../views/tournaments/store';
import predictions from '../views/predictions/store';

export default {
    login,
    settings,
    games,
    blogs,
    categories,
    news,
    bookmakers,
    teams,
    advertisements,
    tournaments,
    predictions
}
