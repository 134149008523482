export default class GamesModel {
    constructor(item) {
        this._setId(item);
        this._setName(item);
        this._setShortName(item);
        this._setImage(item);
        this._setOrder(item);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set name
     * @param name
     * @private
     */
    _setName({name}) {
        this.name = name;
    }

    /**
     * set short name
     * @param shortName
     * @private
     */
    _setShortName({shortName}) {
        this.shortName = shortName;
    }

    /**
     * set image
     * @param photoUrl
     * @private
     */
    _setImage({photoUrl}) {
        this.image = photoUrl;
    }

    /**
     * set order
     * @param orderIndex
     * @private
     */
    _setOrder({orderIndex}) {
        this.order = orderIndex;
    }
}
