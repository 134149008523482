import axios from "axios";
import api from '@/core/api';
import Vue from 'vue';
export default {
    changePass(_, payload) {
        return axios
            .post(api.setting, payload)
            .then((response) => {
                if (response && response.status === 200) {
                    Vue.$toast.success(response.data.message);
                    return true;
                }
            })
            .catch(() => {
                return false;
            });
    },
}
