import axios from "axios";
import store from './../store/index';
import router from '../router';
import Vue from 'vue';

axios.defaults.baseURL = "https://api.csprediction.com/api/v1";
// axios.defaults.baseURL = "http://api.csp.com/api/v1";
// axios.defaults.baseURL = "https://csp-api.thegurbanov.com/api/v1";
// axios.defaults.baseURL = "https://beta-api.csprediction.com/api/v1";

axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("admin.csp");

axios.interceptors.request.use(function (config) {
    store.commit("setLoader", true);
    return config;
}, function (error) {
    store.commit("setLoader", true);
    return Promise.reject(error);
});

axios.interceptors.response.use(
    (response) => {
        store.commit("setLoader", false);
        return response;
    },
    (error) => {
        const {
            response: {status, data},
        } = error;

        console.log("Error: ", error.response);
        // TODO: error mesajlarini duzeltmek
        let errMessage = "Something went wrong";

        if (router.currentRoute.name === "login") {
            if (data && data.data) {
                errMessage = data.data.errors[0];
            } else if (data.Message) {
                errMessage = data.Message;
            } else if (data.ValidationErrors) {
                errMessage = data.ValidationErrors[0].Message;
            } else {
                errMessage = data;
            }
        } else {
            // TODO: response message deyishmek
            if (data) {
                errMessage = data;
            }
            if (status === 401) {
                errMessage = "Session expired";
                store.commit("logout");
            } else if (status === 400 || status === 422) {
                if(typeof data === 'string') {
                    errMessage = data
                }
                else if (data.message) {
                    errMessage = data.message;
                } else {
                    errMessage = data.ValidationErrors[0].Message;
                }
            } else if (status === 404) {
                if (data.message) {
                    errMessage = data.message;
                }
            }
        }
        store.commit("setLoader", false);
        Vue.$toast.error(errMessage);
    }
);

