import TeamsUpTypeModel from './teams-up-type.model';

export default class TeamsModel {
    constructor(item) {
        this._setId(item);
        this._setName(item);
        this._setNameRu(item);
        this._setRank(item);
        this._setPoint(item);
        this._setUpType(item);
        this._setUpRank(item);
        this._setImage(item);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set name
     * @param name
     * @private
     */
    _setName({name}) {
        this.name = name;
    }

    /**
     * set name ru
     * @param name_ru
     * @private
     */
    _setNameRu({name_ru}) {
        this.name_ru = name_ru;
    }

    /**
     * set image
     * @param photoUrl
     * @private
     */
    _setImage({photoUrl}) {
        this.image = photoUrl;
    }

    /**
     * set rank
     * @param rank
     * @private
     */
    _setRank({rank}) {
        this.rank = rank;
    }

    /**
     * set point
     * @param point
     * @private
     */
    _setPoint({point}) {
        this.point = point;
    }

    /**
     * set upType
     * @param upType
     * @private
     */
    _setUpType({upType}) {
        this.upType = new TeamsUpTypeModel(upType);
    }

    /**
     * set upRank
     * @param upRank
     * @private
     */
    _setUpRank({upRank}) {
        this.upRank = upRank;
    }

}
