import LoaderCmp from './components/layout/loader/loader.cmp.vue';
import LeftMenuCmp from './components/layout/left-menu/left-menu.cmp.vue'
import HeaderCmp from './components/layout/header/header.cmp.vue'

export default {
    components: {
        LoaderCmp,
        LeftMenuCmp,
        HeaderCmp
    },
    computed: {
        isLogin() {
            return this.$route.name === "login";
        },
    },
    created() {
        this.$store.dispatch('initAuth').then((res) => {
            if (res) {
                this.$store.dispatch('categories/getItems');
                this.$store.dispatch('teams/getUpTypes');
                this.$store.dispatch('tournaments/getTournamentTypes');
                this.$store.dispatch('tournaments/getItems');
                this.$store.dispatch('games/getItems');
                this.$store.dispatch('predictions/getMatches');
            }
        })
    }
};
