export default {

    setItems(state, payload) {
        state.items = payload;
    },


    deleteItem(state, payload) {
        state.items = state.items.filter((x) => {
            return x.id != payload;
        });
    },

    setMatches(state, payload) {
        state.matches = payload;
    },

    approveResult(state, payload) {
        state.items.find(x => x.id === payload).isEnded = true;
    }

}
