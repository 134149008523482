export default {
    data() {
        return {
            items: [
                {
                    title: "Predictions",
                    icon: "mdi-list-status",
                    link: "predictions",
                },
                {
                    title: "Games",
                    icon: "mdi-google-controller",
                    link: "games",
                },
                {
                    title: "Tournaments",
                    icon: "mdi-medal",
                    link: "tournaments",
                },

                {
                    title: "Advertisements",
                    icon: "mdi-bullhorn",
                    link: "advertisements",
                },


                {
                    title: "Teams",
                    icon: "mdi-account-group",
                    link: "teams",
                },
                {
                    title: "Bookmakers",
                    icon: "mdi-currency-btc",
                    link: "bookmakers",
                },
                {
                    title: "News",
                    icon: "mdi-information-variant",
                    link: "news",
                },
                {
                    title: "Blog",
                    icon: "mdi-post",
                    link: "blogs",
                },
                {
                    title: "Categories",
                    icon: "mdi-format-list-bulleted",
                    link: "categories",
                },

            ],
        };
    },
};
