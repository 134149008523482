import CategoriesModel from '../../categories/models/categories.model';

export default class NewsModel {
    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setTitleRu(item);
        this._setDescription(item);
        this._setMetaTitle(item);
        this._setMetaDescription(item);
        this._setDescriptionRu(item);
        this._setViewCount(item);
        this._setPublishDate(item);
        this._setPublished(item);
        this._setCategory(item);
        this._setImage(item);
        this._setActive(item);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({ id }) {
        this.id = id;
    }

    /**
     * set title
     * @param title
     * @private
     */
    _setTitle({ title }) {
        this.title = title;
    }

    /**
     * set title ru
     * @param title_ru
     * @private
     */
    _setTitleRu({ title_ru }) {
        this.title_ru = title_ru;
    }

    /**
* set title ru
* @param meta_title
* @private
*/
    _setMetaTitle({ meta_title }) {
        this.meta_title = meta_title;
    }

    /**
     * set description
     * @param meta_description
     * @private
     */
    _setMetaDescription({ meta_description }) {
        this.meta_description = meta_description;
    }
    /**
     * set description
     * @param description
     * @private
     */
    _setDescription({ description }) {
        this.description = description;
    }

    /**
     * set description ru
     * @param description_ru
     * @private
     */
    _setDescriptionRu({ description_ru }) {
        this.description_ru = description_ru;
    }

    /**
     * set viewCount
     * @param viewCount
     * @private
     */
    _setViewCount({ viewCount }) {
        this.viewCount = viewCount;
    }

    /**
     * set publishDate
     * @param publishDate
     * @private
     */
    _setPublishDate({ publishDate }) {
        this.publishDate = publishDate;
    }

    /**
     * set published
     * @param published
     * @private
     */
    _setPublished({ published }) {
        this.published = published;
    }

    /**
     * set image
     * @param photoUrl
     * @private
     */
    _setImage({ photoUrl }) {
        this.image = photoUrl;
    }

    /**
     * set active
     * @param active
     * @private
     */
    _setActive({ active }) {
        this.active = active;
    }

    /**
     * set category
     * @param category
     * @private
     */
    _setCategory({ category }) {
        this.category = new CategoriesModel(category);
    }
}
