export default {
    props: {
        title: {
            type: String,
        },
        link: {
            type: String,
            required: false,
        },
    },
}
